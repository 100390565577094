@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700|Material+Icons);
@import url(https://fonts.googleapis.com/css?family=Amatic+SC&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
.navbar {
  background-color: #4169e1;
}

.navbar h3 {
  display: inline-block;
  text-align: left;
  padding: 10px;
  color: black;
  text-decoration: none;
}

.navbar a {
  display: inline-block;
  padding: 10px;
  color: #fff;
  text-decoration: none;
}

.page-info {
  padding: 10px;
}

.Current {
  color: #2e8b57;
}

.Completed {
  color: #ff6347;
  text-decoration: line-through;
}

:root {
    --blueColor: #00a6e4;
    --greenColor: #9FC540;
    --orangeColor: #F5B827;
    --blueColorTransparency: rgba(0, 166, 228, 0.7);
    --greenColorTransparency: rgba(159, 197, 64, 0.7);
    --orangeColorTransparency: rgba(245, 184, 39, 0.7);
    --whiteColorTransparency: rgba(255, 255, 255, 0.3);
  }
  .blueColor {color:#00a6e4;color:var(--blueColor);}
  .greenColor {color:#9FC540;color:var(--greenColor);}
  .orangeColor {color:#F5B827;color:var(--orangeColor);}
  .blueColorTransparency {color:rgba(0, 166, 228, 0.7);color:var(--blueColorTransparency);}
  .greenColorTransparency {color:rgba(159, 197, 64, 0.7);color:var(--greenColorTransparency);}
  .orangeColorTransparency {color:rgba(245, 184, 39, 0.7);color:var(--orangeColorTransparency);}
  .whiteColorTransparency {color:rgba(255, 255, 255, 0.3);color:var(--whiteColorTransparency);}
  .blueColorBg {background-color:#00a6e4;background-color:var(--blueColor);}
  .greenColorBg {background-color:#9FC540;background-color:var(--greenColor);}
  .orangeColorBg {background-color:#F5B827;background-color:var(--orangeColor);}
  .blueColorBgTransparency {background-color:rgba(0, 166, 228, 0.7);background-color:var(--blueColorTransparency);}
  .greenColorBgTransparency {background-color:rgba(159, 197, 64, 0.7);background-color:var(--greenColorTransparency);}
  .orangeColorBgTransparency {background-color:rgba(245, 184, 39, 0.7);background-color:var(--orangeColorTransparency);}
  .whiteColorTransparencyBg {background-color:rgba(255, 255, 255, 0.3);background-color:var(--whiteColorTransparency);}

.Loading {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position:absolute;
    z-index:1000;
}
.Loading svg{
    background-color: transparent!important;
    padding-top: 75px;
}
.btInstall{
    width: calc(100% - 40px);
    margin: 0 20px 20px 20px!important;
    background-color: #F5B827!important;
    background-color: var(--orangeColor)!important;
    color: white!important;
    font-size: 12px!important;
}
.btInstall:hover{
    background-color: rgba(245, 184, 39, 0.7)!important;
    background-color: var(--orangeColorTransparency)!important;
}
.borderBottom {
    border-bottom: 1px rgba(255, 255, 255, 0.3) solid;
    width: 100%;
    color: white;
}
.borderBottom > span {
    font-weight: 300;
    font-family: Montserrat;
    color: white;
    text-transform: uppercase;
    font-size: 14px;
}
/*.MuiGrid-spacing-xs-2{
    width: auto!important;
    margin: 0px!important;
}
.MuiGrid-spacing-xs-2 > .MuiGrid-item, .MuiBox-root{
    padding: 0px!important;
}
.teste {
    background-color: red;
}*/
.bgImage {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    width:100%;
    background-color: black;
    position:fixed;
    left:0px;
    z-index: -1;
    animation: fadein 2s;
  }
.bgImageDark {
    background-Color: rgba(0,0,0,0.4);
    min-height: 100vh;
    min-height: -webkit-fill-available;
    width:100%;
    position:fixed;
    left:50%;
    z-index: -1;
    max-width:768px;
    transform:translate(-50%, 0)
  }
.bgImageDarkBlur {
    -webkit-backdrop-filter: blur(60px); 
    backdrop-filter: blur(60px);
  }
.header {
    padding: 20px;
    background-color: none;
}
.sair {
    background-color: transparent!important;
    border: 0!important;
}
.companyLogo {
    float:right;
}
.companyLogo img{
    height: 40px;
}
.headerSpacer {
    padding-top: 20px;
}
button:focus {
    outline: none;
}
.influencies li:first-child {
    background-color: rgba(255, 255, 255, 0);
}
.paperInfluence {
    padding: 25px;
    max-height: 100px;
    overflow-y: auto;
    text-transform: none;
    color: rgba(255, 255, 255, 0.5)!important;
}
.influenceFirstLine {
    background-color: transparent!important;
    border-bottom: solid 0px rgba(255,255,255,0.3)!important;
}
.thumbIcon {
    width: 36px;
    height: 36px;
    padding: 0!important;
}
.thumbIcon.like,
.thumbIcon.like:hover{
background-color: #F5B827!important;
}
.thumbIcon.unlike,
.thumbIcon.unlike:hover{
    background-color: #00A6E4!important;
    }
.thumbIcon svg{
    width: 0.8em;
    color: rgba(255, 255, 255, 0.3);

}
.thumbIcon.like svg,
.thumbIcon.unlike svg{
    color: white;
}
.InspirationQuotes {
    margin-top: 16vh;
}
.FooterHome {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
}
.FooterHome .appBts{ /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
    animation: fadein 2s;
}
@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */

/* Internet Explorer */

/* Opera < 12.1 */
.LogoContainer {
    position: absolute;
    bottom: 230px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
}
.quote {
    font-family: 'Amatic SC', 'Arial Narrow', Arial, sans-serif;
    font-size: 52px;
    text-align: center;
    line-height: 1.1;
    color: white;
    padding: 0 120px; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
    animation: fadein 2s;
}
.comment{
    width: 360px;
}
.commentTextField {
    padding: 0 28px!important;
    width: 100%;
    box-sizing: border-box;
}
.MedalsNumberStar{
    display: flex;
    align-items: center;
    justify-content: center;
}
.MedalsNumber, .MedalsNumberAssign {
    font-size: 24px;
    font-weight: 300!important;
    margin-right: 10px;
    text-align: center;
    font-family: Montserrat;
    color: white;
    min-width: 40px;
}
.MedalsNumberAssign{
    margin: 0px;
    min-width: 18px;
}
.PersonalInfoContent .MedalsNumber{
    font-size: 32px;
}
.MedalsList > div:first-child,
.MedalsList > li:first-child {
    background: linear-gradient(0deg, rgba(255,255,255,0.1) 50%, rgba(255,255,255,0) 100%);
}
.MedalsList {
    height: calc(100vh - 196px)!important;
    overflow-y: auto; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
    animation: fadein 2s;
}

.PersonalInfo {
    background-color: rgba(0,0,0,0.3);
    height: 50vw;
    max-height: 400px;
    background-size: cover;
    background-position:0 35%;
    position: relative;
    animation: fadein 1s;
}
.PersonalInfoNoPhoto {
    background-size: 85% 85%;
    background-repeat: no-repeat;
    /*background: linear-gradient(135deg, hsl(100, 25%, 80%) 0%, hsl(100, 30%, 50%) 100%)!important;*/
}
.PersonalInfoNoPhoto .NoPhoto{
    position: absolute;
    height: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-20deg);
}
.NoPhotoAvatar{
    /*background: linear-gradient(135deg, hsl(100, 25%, 80%) 0%, hsl(100, 30%, 50%) 100%)!important;*/
    position: relative;
}
.NoPhotoAvatar > img{
    position: absolute;
    height: 60%!important;
    width: 60%!important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-20deg);
}
.PersonalInfo .PersonalInfoDark{
    background-color: rgba(0,0,0,0.3);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
}
.PersonalInfo .PersonalInfoName {
    position: absolute;
    bottom: 16px;
    left: 16px;
    z-index: 10;
}
.PersonalInfo .PersonalInfoName *{
    color: white;
    font-weight: 400;
    font-family: Montserrat;
    font-size: 14px;
}
.PersonalInfo .PersonalInfoName div{
text-transform: uppercase;
}
.PersonalInfo .PersonalInfoName span{
    font-size: 12px;
}
.PersonalInfo .MedalsNumberStar {
    position: absolute;
    bottom: 16px;
    right: 16px;
    z-index: 10;
}
.PersonalInfo .PersonalInfoRelations{
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
}
.PersonalInfo .PersonalInfoRelations svg{
    color: white;
}
.PersonalInfo .CompanyLogo img {
    max-width: 80px;
    max-height: 40px;
}

.mensagensNumero {font-size: 12px; color: black; position: absolute; font-family: Montserrat; font-weight: 700; top:7px}
.Mui-selected {color: #FFFF8D!important;}
.appWidth {max-width: 768px; margin: auto;}

.MedalsAvatarList{flex-basis: 100%; margin-top: 8px;}

.MuiCollapse-container {width: 100%;}
.historyIcon {float: right; padding: 10px!important;}

.DetailMedalsList {height: calc(100vh - 400px - 64px)!important; overflow-y: auto;}
.DetailMedalsList2 {height: calc(100vh - 400px - 168px)!important; overflow-y: auto;}
.DetailHistory, .NewsTxtDetail {height: calc(100vh - 400px - 104px)!important; overflow-y: auto;}

.DetailMedalsList, 
.NewsTxtDetail,
.DetailMedalsList2,
.DetailHistory { /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
    animation: fadein 2s;
}

.DetailHistory .detail .MedalsNumber {color: rgba(255, 255, 255, 0.3);}
.DetailHistory .detail .MuiSvgIcon-root {fill: white;}

.MuiCollapse-container .MuiCollapse-wrapper *,
.MuiCollapse-container .MuiCollapse-wrapper:hover *{
    text-transform: none;
    background-color: rgba(0,0,0,0);
    border-bottom: 0px;
}
.MuiCollapse-container .MuiCollapse-wrapper p{
    font-style: italic; font-size: 12px;
}
.MuiCollapse-container .MuiCollapse-wrapper .MuiListItem-root{
    padding: 10px 0px 10px 56px;
}
.ListBorderSpacer {border-bottom: 1px solid rgba(255, 255, 255, 0.3)!important; width: 100%; margin-bottom: 10px;}
.MuiListItemIcon-root {position: relative;}
.MuiListItemIcon-root .svgChat {position: absolute; right:8px; top: -8px; width: 18px!important; color: white;}
.MuiCollapse-container .hour {padding-top: 8px; color: rgba(255, 255, 255, 0.3);}

.ChartsContainer {
    height: 50vw;
    max-height: 400px;
    position: relative;
    display: flex;
    align-items: center;
    overflow-x: auto;
}
.divSpacer{
    padding: 10px;
}
.ChartsContainer .cardGraphic {
    padding: 5px; 
    background-color: rgba(0,0,0,0.3); 
    margin: 0 0 0 20px;
    height: 40vw;
    max-height: 350px;
    width: 80vw;
    min-width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.modals .MuiPaper-root {/*max-width: 360px;*/ min-width: 360px; overflow-y:visible; animation: fadein 1s; }
.modals .ModalsComments {padding-bottom: 30px;}
.modals .MuiDialogContent-root p {color:rgba(255, 255, 255, 0.54); text-transform: none;}
.modals .MuiTextField-root {width: 100%;}
.ModalsHeader {
    height: 110px; 
    display: flex; 
    background: radial-gradient(circle at 50% 160%, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 60%);
    align-items: center;
    justify-content: center;
}
.ModalsHeader.blue {
    background: radial-gradient(circle at 50% 160%, #00a6e4 0%, rgba(255,255,255,0) 60%);
    background: radial-gradient(circle at 50% 160%, var(--blueColor) 0%, rgba(255,255,255,0) 60%);
}
.ModalsHeader.green {
    background: radial-gradient(circle at 50% 160%, #9FC540 0%, rgba(255,255,255,0) 60%);
    background: radial-gradient(circle at 50% 160%, var(--greenColor) 0%, rgba(255,255,255,0) 60%);
}
.ModalsHeader.orange {
    background: radial-gradient(circle at 50% 160%, #F5B827 0%, rgba(255,255,255,0) 60%);
    background: radial-gradient(circle at 50% 160%, var(--orangeColor) 0%, rgba(255,255,255,0) 60%);
}
.ModalsHeaderShadow{
    height: 40px;
    background: linear-gradient(to bottom,  rgba(0,0,0,0.1) 0%,rgba(0,0,0,0) 70%);

}
.ModalsHeader > div{
    align-self: flex-end;
    margin-bottom: -35px;
}
.ModalsHeader .logo {max-width: 160px; max-height: 80px; padding-bottom: 10px;}
.avatarModals {position: absolute!important; width: 76px!important; height: 76px!important; top:47px; left: 50%; transform: translate(-50%, 0);}
.avatarComments {position: absolute!important; width: 56px!important; height: 56px!important; top:-28px; left: 50%; transform: translate(-50%, 0);}

.new_medal {padding-left: 47px; z-index: 10; position: absolute!important;left: 50%; transform: translate(-50%, 0);top:12px; }
.floatLeft {float: left;}


.MuiInput-underline:before {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5)!important;
}
.MuiInput-underline:after {
    border-bottom: 2px solid #FFFF8D!important;
}

/* HISTORY */
.HistoryCards {
    height: calc(33vh - 58px )!important;
    max-height: 400px;
    position: relative;
    display: flex;
    overflow-x: auto;
    padding: 14px 0 6px 0;
}
.HistoryChartCard, 
.HistoryIconCard { 
    background-color: rgba(0, 0, 0, 0.3);
    margin: 0 0 0 20px;
    height: 100%;
    width: 50vw;
    min-width: 212px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    box-sizing: border-box;
}
.HistoryIconCard { 
    justify-content: center;
}
.MyDate{
    color: white;
    font-size: 14px;
    font-weight: 300;
    font-family: Montserrat;
    text-align: center;
    height: 36px;
    line-height: 56px;
}
.HistoryIcon {
    text-align: center;
}
.HistoryIcon svg{
    /*width: 70px;
    height: 70px;*/
    fill: rgba(255, 255, 255, 1);
}
.HistoryNumber {
    text-align: center;
    color:white;
    font-size: 42px;
    font-weight: 300;
    font-family: Montserrat;
}
.BarChartHistory {
    width: 100%; 
    height: 50%; 
    padding: 16px; 
    box-sizing: border-box;
}
.ChartDescription {
    padding: 16px;
    color: white;
    font-weight: 300;
    font-family: Montserrat;
    font-size: 12px;
}
.noBg {background-color: rgba(255,255,255,0)!important;}

.copy {font-size: 9px; font-family: Montserrat; font-size: 10px; display: flex; align-items: center; justify-content: center; margin-top: 10px; color:white}
.copy > span{margin: 0 0 0 5px; font-style: italic; text-transform: none!important;}
.copy svg {fill:rgba(255, 255, 255, 0.5); width: 16px;}
.copy .MuiButtonBase-root {padding: 0;}

/*.version {position: absolute; left:5px; bottom: 5px; color: white; font-size: 10px; font-family: Montserrat;}*/

.MedalsNumberStar > .MedalsNumberStar > .MedalsNumber {font-size: 14px; min-width: auto; margin-right: 5px; text-align: right;}
.MedalsNumberStar > .MedalsNumberStar svg {width: 16px!important;}



/* TEMP CONTENT */
.tempContent * {font-family: 'Montserrat';}
.tempContent {
    display: flex; 
    align-items: center; 
    justify-content: center;
    flex-direction: column;
    width: 100%; 
    height: calc(100vh - 66px); /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
    animation: fadein 2s;
}
.HappyShop .tempContent {height: calc(100vh - 220px);}
.tempContent.intab {
height: calc(100vh - 122px);
}
.tempContent > div {padding: 40px 150px; text-align: center; color: white;}
.tempContent .title {margin: 20px 0; text-transform: uppercase; font-size: 20px;}
.tempContent .text {line-height: 1.5; color: rgba(255, 255, 255, 0.5);}
.tempContent .soon {margin: 20px 0; text-transform: uppercase; font-size: 10px;}
.tempContent .soon a{color: white; text-decoration: none;}
.tempContent.noHeight {height: auto;}
.moodVertical{
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: calc(var(--app-height) - 64px);
        text-align: center; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
       animation: fadein 2s;
}

.moodCategoriesList{
    height: calc(100vh - 297px); 
    overflow-y: auto; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
    animation: fadein 2s;
}
.MoodQt.moodCategoriesList{
    height: calc(100vh - 269px); 
}

.moodList .MuiCollapse-container .MuiCollapse-wrapper .MuiListItem-root {padding: 10px 14px 10px 10px;}
.moodList .moodCategories  {
    z-index: -1;
}
.moodList .moodCategories .MuiListItemIcon-root {
    min-width: auto;
    color: white;
    vertical-align: middle;
    text-align: center;
    padding: 4px;
}
.moodList .moodCategories .MuiListItemIcon-root .MuiTypography-body1{
    font-size: 12px;
    width: 20px;
    padding: 0px 2px 0px 0px;
}
.moodList .MuiListItem-container .MuiListItem-root {border-bottom: solid 0px rgba(255,255,255,0.3);border-top: solid 1px rgba(255,255,255,0.3);}

.titleHeader{
    position:absolute;
    left:50%;
    transform: translate(-50%,0);
}

.homeLoader{
    display: inline-block;    
    position: relative;
    width: 96px;
    height: 124px;
    vertical-align: top;
}

.homeLoader svg{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
}

.MuiIconButton-root.Mui-disabled{
    color: rgba(255,255,255,0.3) !important;
}

.CampaignText, .MyCoins,.objective{
    box-sizing: border-box;
    padding: 20px!important;
    width: 100%;
    max-width: 420px;
}
.CampaignText{
    text-transform: none;
    font-size: 16px;
}
.CampaignText a{
    color:#F5B827;
    color:var(--orangeColor);
    text-decoration: none;
    margin-bottom: 20px;
    display: block;
}
.CampaignText .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {background-color:#F5B827!important;background-color:var(--orangeColor)!important;}
.MyCoins{
    background-color: rgba(255,255,255,0.1);
    width: 90%;
    border-radius: 20px;
}
.MyCoins .txt{
    margin-bottom: 10px;
    font-size: 18px;
}
.MyCoins .number{
    font-size: 32px;
    color:#F5B827;
    color:var(--orangeColor);
}
.objective .txt,
.HappyShopItemDetail .txt{
    font-size: 18px;
}
.HappyShopItemDetail .txt{
    width: 100%;
    display: inline-block;
    text-align: center;
    text-transform: uppercase;
}
.objective .txt span,
.HappyShopItemDetail .txt span{
    font-weight: 700;
}
.objective .txt:first-child{
    margin-bottom: 40px;
    display: block;
}
.objective .MuiSlider-track{
    background-color:#F5B827;
    background-color:var(--orangeColor);
}
.objective .MuiSlider-markLabel{
    color: white;
}
.objective .MuiSlider-mark{
    margin-top: 5px;
}
.objective .MuiSlider-thumb{
    margin-top: 2px!important;
    background-color:rgba(255, 255, 255, 0);
}
.objective .MuiSlider-valueLabel{left:-15px}
.objective .MuiSlider-valueLabel > span {background-color: white;}
.objective .MuiSlider-valueLabel > span > span {color:black}

/* MOOD QUESTION TYPES */
/*.MoodQt > div {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 10px 20px;
    background-color: rgba(255,255,255,0.1);
    /*border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    margin: 0 80px 20px 80px;
    border-radius: 16px;
}*/
.MoodQt .qtLocal {
    background-color:  rgba(255,255,255,0);
    padding: 0 20px 10px;
    margin-bottom: 0px;
}
.MoodQt .txt{
    width: 100%;
    display: block;
    text-align: center;
    padding: 10px;
    /*text-transform: uppercase;*/
    line-height: 1.2;
    font-size: 14px;
    color: rgba(255,255,255,0.8);
    font-family: 'Montserrat'
}
.MoodQt .qtOne .txt {font-size: 16px; text-align: left;}
.MoodQt .qtLocal .txt{
    padding-bottom: 5px;
    font-size: 10px;
}
.MoodQt .MuiButtonBase-root {
    /*text-transform: uppercase;*/
    font-size: 14px;
    margin: 3px;
    background-color: rgba(255, 255, 255, 0.3);
    color: black;
    margin: 3px 3px 8px 3px;
}
.MoodQt .qtOne .MuiButtonBase-root {width: 100%;}
.MoodQt .MuiButtonBase-root:hover,
.MoodQt .MuiButtonBase-root:focus, 
.MoodQt .MuiChip-outlined,
.MoodQt .MuiChip-outlined:hover,
.MoodQt .MuiChip-outlined:focus {
    text-transform: uppercase;
    /*font-size: 10px;*/
    /*margin: 3px;*/
    background-color: #F5B827!important;
    background-color: var(--orangeColor)!important;
    color: white!important;
    text-transform: none;
}
.MoodQt .MuiChip-label {width: 100%;}
/*.MoodQt .MuiButtonBase-root svg {color: black;}*/
.MoodQt .MuiChip-outlined svg {color: white;}

.MoodQt form, .MoodQt .MuiFormControl-root  {width: 100%;}
.MoodQt form label {color: white!important;font-size:12px;}

.LogoContainer .CompanyLogo img {max-width: 150px; max-height: 100px;}

/*.mooodSwipeable .react-swipeable-view-container > div {margin-bottom: 56px;}*/

.MoodQt.MoodModal {width: 600px; box-sizing: border-box;}
.MoodQt.MoodModal div {box-sizing: border-box;}
.MoodQt.MoodModal .qtOne {padding: 0 70px;}

.MoodQt.MoodModal .Medals {padding-top: 10px;}
.MoodQt.MoodModal .Medals div {padding-top: 5px; font-weight: 500; color: white; font-size: 22px; font-family: Montserrat!important;}
.MoodQt.MoodModal .Medals svg {width: 100px; height: auto;}


.MoodQt .btQuestions .MuiButtonBase-root {
    text-transform: uppercase!important;
    background-color: transparent;
}

/* HAPPYSHOP */
.HappyShop .HappyCoins,
.NewsDetail .HappyCoins {
    height: 60px; 
    width: 60px; 
    line-height: 60px;
    background-color: #00a6e4;
    background-color: var(--blueColor); 
    color: white; 
    text-align: center; 
    font-size: 18px; 
    font-family: Montserrat!important; 
    font-weight: 600;
    position: absolute; 
    right: 15px; 
    top: 15px; 
    border-radius: 50%;
    z-index: 100;
}
.HappyShop .MyHappyCoins {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}
.HappyShop .MyHappyCoins .title {font-size: 46px; color: #00a6e4; color: var(--blueColor)}
.HappyShop .MyHappyCoins .txt {width:50px; text-align: right;}
.HappyShop .MyHappyCoins div {margin: 0 8px;}
.HappyShop .MyHappyCoins svg { width: 50px; height: 70px; padding-top: 8px;}
.HappyShop .HappyShopItem {
    margin: 0 80px 20px 80px; 
    background-color: rgba(255, 255, 255, 0.1); 
}

.HappyShop .MuiTypography-body2{
    color: rgba(255,255,255,0.5);
    font-size: 12px;
    text-transform: none;
}
.HappyShop .CatalogList {
    height: calc(100vh - 216px);
    overflow-y: auto;
    animation: fadein 2s;
    width: 100%;
}
.HappyShop .Photo{height: 240px;}
.NewsDetail .objective {
    max-width: 100%; 
    background-color: rgba(255, 255, 255, 0.1); 
    padding: 15px 20px 20px 20px!important; 
    text-align: center; 
    border-radius: 20px; 
    margin-bottom: 20px;
}
.NewsDetail .objective .MuiSlider-track {
    background-color: #00a6e4;
    background-color: var(--blueColor);
}
.StoreItemBts {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;;
}
.StoreItemBts .MuiButton-containedPrimary {
    background-color: #00a6e4;
    background-color: var(--blueColor);
} 
.StoreItemBts .Mui-disabled span{color: rgba(255, 255, 255, 0.5);}
.HappyShop .NoStock,
.HappyShop .NoCoins {position: absolute; top: 200px; right: 15px; background-color: red; padding: 5px 10px; border-radius: 4px; font-weight: 700; text-transform: uppercase; font-size: 10px; font-family: 'Montserrat', sans-serif; color: white;}
.HappyShop .NoCoins {background-color: orange;}
.HappyShop .MyHappyCoins {color: white;}
.HappyShop .Coins {color: white; font-size: 18px;font-weight: 500!important;font-family: Montserrat;}
.HappyShop .Coins.red {color: red;}
.HappyShop .CoinsList {height: calc(var(--app-height) - 158px); overflow-y: auto; animation: fadein 2s;}



/* NEW MERITS HIGHLIGHT BLOCKS */
.MedalsHighlightsWrapper {position: relative;}
.MedalsHighlightsWrapper .Nav {position: absolute; width: 100%; padding: 20px; box-sizing: border-box; z-index: 10;}
.MedalsHighlightsWrapper .Nav a:first-child {float: left; display: block;}
.MedalsHighlightsWrapper .Nav a:last-child {float: right; display: block;}
.MedalsHighlights {padding: 20px 0px; text-align: center; /*font-size: 0;*/ margin: 0; animation: fadein 1s;}
.MedalsHighlights .block {width: 50%; max-width: 270px; display: inline-block; vertical-align: top; padding: 20px; text-align: center; position: relative; box-sizing: border-box; cursor: pointer; border-radius: 4px; transition: 150ms;}
.MedalsHighlights .block:hover {background-color: rgba(255,255,255,0.1); transition: 150ms;}
.MedalsHighlights .block .Avatar,
.MedalsHighlights .block .NoPhotoAvatar {width: 100%; height: 100%;}
.MedalsHighlights .badge {position: absolute; right: 20px; top: 20px; font-size: 24px; line-height: 70px; z-index: 10; width: 70px; height: 70px; border-radius: 50%;}
.MedalsHighlights .name {font-size: medium; margin-top: 20px;}
.MedalsHighlights .title {font-size: 16px; font-weight: 500; margin: 0 20px 20px 20px; padding-bottom: 10px;}
.MedalsHighlights .block .NoPhotoAvatar {padding-top: 100%;}

.MedalsHighlights .rec-slider-container {margin: 0px!important;}
.MedalsHighlights .rec-arrow-left {margin: 0 -60px 0 10px; z-index: 100;}
.MedalsHighlights .rec-arrow-right {margin: 0 10px 0 -60px; z-index: 100;}
/*.MedalsHighlights .rec-carousel button {display: none;}
.rec-arrow {background-color: rgba(255,255,255,0.5)!important; color: red;}
button:disabled,
button[disabled] {background-color: yellow!important;}*/
.rec.rec-pagination{margin-top: 40px;}
.rec.rec-arrow, .rec.rec-arrow:focus {
    background-color: rgba(255, 255, 255, 0.2)!important;
    color: white;
}
.rec.rec-arrow:hover {
    background-color: #00a6e4!important;
    background-color: var(--blueColor)!important;;
}
.rec.rec-arrow:disabled {
    visibility: hidden;
}
.rec.rec-dot {
    background-color: rgba(255, 255, 255, 0.2)!important;
    box-shadow: none!important;
}
.rec.rec-dot_active{background-color: white!important;}

.help img {max-width:250px;padding-bottom: 20px;}
.help p {padding:20px 60px 0px 60px;}

.MuiListItem-container .whois {display: flex; align-items: center;}
.MuiCollapse-entered .MuiBadge-root {margin: 12px 0 8px 12px;}
 

@media only screen and (min-height: 731px) and (max-height: 900px) {
    .MedalsHighlightsWrapper {padding-top: 6vh;}
}

@media only screen and (max-width: 768px) {

    .MuiDrawer-paper {-webkit-backdrop-filter: blur(60px) brightness(2)!important;backdrop-filter: blur(60px) brightness(2)!important;}

    .MuiBottomNavigation-root {width: 100%!important;}
    /*.DetailMedalsList {height: calc(100vh - 50vw - 64px)!important;}
    .DetailMedalsList2 {height: calc(100vh - 50vw - 160px)!important;}
    .DetailHistory {height: calc(100vh - 50vw - 92px)!important;}
    .moodCategoriesList{height: calc(100vh - 205px);}*/
    
    .DetailMedalsList {height: calc(var(--app-height) - 50vw - 64px)!important;}
    .DetailMedalsList2 {height: calc(var(--app-height) - 50vw - 160px)!important;}
    .DetailHistory, .NewsTxtDetail {height: calc(var(--app-height) - 50vw - 96px)!important;}
    .moodCategoriesList{height: calc(var(--app-height) - 282px);}
    .MoodQt.moodCategoriesList{height: calc(var(--app-height) - 261px); }


    .ChartsContainer::-webkit-scrollbar,
    .HistoryCards::-webkit-scrollbar {display: none;}

    /*.MoodQt > div {margin: 0 20px 20px 20px;}*/
    .HappyShop .HappyShopItem {margin: 0 20px 20px 20px;}
    .HappyShop .CatalogList {height: calc(100vh - 208px);}

    .MedalsHighlights .rec-carousel button {display: none;}
    .MedalsHighlights {padding: 20px 0px; }
    .MedalsHighlights .MedalsHighlightsCategory {padding: 0px 20px; }
    .MedalsHighlights .badge {font-size: 2.7vw; line-height: 8.6vw; width: 8.6vw; height: 8.6vw;}
    .MedalsHighlights .name {font-size: 2.7vw; margin-top: 20px;}

    .rec.rec-pagination{margin-top: 20px;}
    .MedalsHighlights .title {margin: 0 0 20px 0;}

    .MoodQt.MoodModal {width: auto;}
    .MoodQt.MoodModal .qtOne {padding: 0 20px;}

    .MedalsList {height: calc(100vh - 180px)!important;}


}

@media only screen and (min-width: 480px) {
    /* width */
    ::-webkit-scrollbar {width: 8px;}
    /* Track */
    ::-webkit-scrollbar-track {background: rgba(255, 255, 255, 0.3);}
    /* Handle */
    ::-webkit-scrollbar-thumb {background: rgba(255, 255, 255, 0.5);}
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {background: white;}
}

@media only screen and (max-width: 480px) {
    /*.DetailMedalsList {height: calc(100vh - 50vw - 56px)!important;}*/
    .DetailMedalsList {height: calc(var(--app-height) - 50vw - 56px)!important;}
    /*.DetailMedalsList2 {height: calc(100vh - 50vw - 160px)!important;}*/
    .DetailMedalsList2 {height: calc(var(--app-height) - 50vw - 160px)!important;}

    .quote {
        font-size: 6vh;
        padding: 0 20px;
    }
    .InspirationQuotes {
        margin-top: 14vh;
    }
    .tempContent > div {padding: 50px 10px 10px 10px;}
    .tempContent {justify-content: flex-start;}

    .HappyShop .Photo {height: 180px;}

    .MedalsHighlights {padding: 20px 0px;}
    .MedalsHighlights .block {padding: 10px; }
    .MedalsHighlights .badge {top: 10px; right: 10px; font-size: 3.5vw; line-height: 10vw; width: 10vw; height: 10vw;}

    .HappyShop .NoStock,
    .HappyShop .NoCoins {top: 142px;}

    .help img {max-height:150px;}
    .help p {padding:0; line-height: 1.3; font-size: 0.95rem;}

  }

  @media only screen and (max-width: 360px) {
  .modals .MuiPaper-root {max-width: 300px; min-width: 300px; }
  }

/* FIREFOX ALTERNATIVE TO backdrop-filter */
  @-moz-document url-prefix() {
    .altBgImage {
        filter: blur(60px); 
    }
    body {background-color: black;}

    .MuiDrawer-paper, .MuiDialog-paper {background-color: rgba(0,0,0,0.8)!important}
  }

  /* .MuiDialog-paper {backdrop-filter: blur(60px) brightness(2); background-color: transparent!important;} */
  .MuiDialogTitle-root {font-size: 16px!important;
    font-family: Montserrat!important;
    font-weight: 500!important;
    line-height: 1.6!important;
color:white!important;
text-transform: uppercase;}
.MuiButton-label {
    color:white;font-family: Montserrat!important;
}

.NewsList {height: calc(var(--app-height) - 58px); overflow-y: auto; animation: fadein 2s;}
.NewsList .NewsImg {width: 62px; height: 62px; overflow: hidden; position: relative; margin: 6px 0;}
.NewsList .NewsImg img {height: 100%; min-width: 62px; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);}
.NewsList > div {display: block;}
.NewsList > div > div {display: inline-block; vertical-align: top;}
.NewsList > div .NewsText {width: calc(100% - 80px); padding-left: 10px;}

.NewsDetail .NewsDetailImg {height: 50vw; max-height: 400px; overflow: hidden; position: relative; width: 100%; animation: fadein 1s;}
.NewsDetail .NewsDetailImg img {width: 100%; min-height: 100%; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);}
.NewsDetail .NewsTxtDetail {font-family: 'Montserrat'; padding: 20px;}
.NewsDetail .NewsTxtDetail .NewsTitle{font-size: 20px; padding: 0 0 20px 0; text-transform: uppercase; color: white;}
.NewsDetail .NewsTxtDetail .NewsText{line-height: 1.5; color: rgba(255,255,255,0.5);}

#profilePictureArea img{max-width: 300px; width: 100%; border-radius: 50%;}
.deletePhoto {
    padding: 4px 10px;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    text-transform: uppercase;
    border: 0;
    cursor: pointer;
}
.filled{
    background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.1) 100%);
    color: white!important;
}
.filled:after{
    content: "¬";
    font-size: 14px;
    position: absolute;
    top: -4px;
    right: 3px;
    color: white!important;
    transform: rotate(135deg);
}

/* slightly transparent fallback */
.MuiDialog-paper {
    background-color: rgba(0, 0, 0, .7)!important;
  }
  /* if backdrop support: very transparent and blurred */
  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .MuiDialog-paper {
        background-color: transparent !important;
        -webkit-backdrop-filter: blur(60px) brightness(2);
        backdrop-filter: blur(60px) brightness(2);
    }
  }

  .recharts-wrapper tspan, .custom-tooltip{
      color: white;
      fill: white;
      font-family: Montserrat;
      font-size: 9px;
  }
  .recharts-wrapper line {stroke: white;}
  .custom-tooltip {background-color: rgba(0,0,0,0.3);}
  .custom-tooltip .label {padding: 4px 6px;}
